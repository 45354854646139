import Page from "../../components/Page";
import LoginView from "../../components/LoginView";

const LoginPage = () => <Page
  title='Login'
  // width='narrow'
  suppressAccountMenu
>
  <LoginView />
</Page>

export default LoginPage

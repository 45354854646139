import { useEffect, useRef } from 'react'

type Props = {
  children?: React.ReactNode | React.ReactNodeArray,
  alignment?: 'start' | 'center' | 'end',
}

const ScrollToMe = ({ children, alignment = 'end' }: Props) => {
  const scrollToRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const scroll = () => {
      scrollToRef.current?.scrollIntoView({ behavior: 'smooth', block: alignment })
      // scrollToRef.current && window.scrollTo(0, scrollToRef.current.offsetTop)
    }
    // requestAnimationFrame(scroll)
    setTimeout(scroll, 500)
  }, [scrollToRef])
  return <div ref={scrollToRef}>
    { children }
  </div>
}

export default ScrollToMe

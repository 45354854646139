import { colorRed } from 'styles/colors.module.css'
import { Checkbox as MaterialCheckbox, CheckboxProps as MaterialCheckboxProps, FormControlLabel, NoSsr, withStyles } from '@material-ui/core'

const RedCheckbox = withStyles({
  root: {
    color: colorRed,
    '&$checked': {
      color: colorRed,
    },
  },
  checked: {},
})((props) => <MaterialCheckbox color="default" {...props} />);

interface CheckboxProps extends MaterialCheckboxProps {
  label: string,
}

const Checkbox = (props: CheckboxProps) => <NoSsr>
  <FormControlLabel
    value={props.value}
    control={<RedCheckbox {...props} />}
    label={props.label}
    labelPlacement='end'
  />
</NoSsr>

export default Checkbox

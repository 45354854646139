import SignUpOrIn from '../SignUpOrIn'
import styles from './loginView.module.css'
import { useRouter } from 'next/router'
import { loginUser, useUser } from 'modules/User/remote'
import { DEFAULT_POST_LOGIN_PAGE, isFullUser } from 'utilities/user'
import { UserType } from 'modules/User/types'

const LoginView = () => {
  const router = useRouter()
  const { user, isUserError } = useUser()

  if (user?.role && isFullUser(user.role)) {
    const { redirectTo } = router.query
    router.push(redirectTo as string || DEFAULT_POST_LOGIN_PAGE)
  }

  return <div className={styles.panel}>
    <SignUpOrIn
      user={user}
      userError={isUserError}
      onSubmit={(userToLogin: UserType) => { loginUser(userToLogin) }}
      isLogIn
    />
  </div>
}

export default LoginView

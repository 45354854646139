import { map, upperFirst } from 'lodash'
import { useState, FormEvent, useEffect } from 'react'
import PhoneInput from 'react-phone-number-input/input'
// import { PhoneInputProps } from 'react-phone-number-input'
import Link from 'next/link'
import Button from 'components/Button'
import * as typeStyles from 'styles/typography.module.css'
import styles from './signUpOrIn.module.css'
import ScrollToMe from '../ScrollToMe'
import * as config from '../../shared/config'
import Router from 'next/router'
import Checkbox from 'components/Checkbox'
import Clickable from 'components/Clickable'
import { UserType } from 'modules/User/types'

const handleUpdateName = (updateUser: (userData: Object) => void) => (e:any) => {
  updateUser({ name: e.target.value })
}

const handleUpdatePhone = (updateUser:any) => (phone:any) => {
  updateUser({ phone })
}

const handleUpdateEmail = (updateUser:any) => (event:any) => {
  updateUser({ email: event.target.value })
}

type SignUpOrInProps = {
  user?: UserType,
  userError?: Error,
  onSubmit: Function,
  isLogIn?: boolean,
}

const SignUpOrIn = ({ user: initialUser, userError, onSubmit, isLogIn = false }: SignUpOrInProps) => {
  const [user, setUser] = useState<UserType | undefined>(initialUser)
  const updateUser = (updatedUser: any) => {
    console.log({ updatedUser })
    setUser({ ...user, ...updatedUser })
  }
  const [isStayLoggedIn, setisStayLoggedIn] = useState(true)
  const toggleisStayLoggedIn = () =>
    setisStayLoggedIn(!isStayLoggedIn)

  const isUserSubmittable = (user: any, isLogIn: boolean) => (user?.name || isLogIn) && (user?.phone || user?.email)
  const getQueryPath = (): string => {
    const { redirectTo } = Router.query
    // if (!(user?.phone || user?.email)) {
    //   return ''
    // }
    // const contactQuery = user?.phone
    //   ? `phone=${user.phone}`
    //   : `email=${user.email}`
    const redirectQuery = redirectTo
      ? `?redirectTo=${redirectTo}`
      : ''
    return redirectQuery
    // return `?${contactQuery}${redirectQuery}`
    // Router.query
    // ? `?${Router.asPath.split(/\?/)[1]}`
    // : ''
  }

  const [isUsingPhoneNumberToConfirm, setIsUsingPhoneNumberToConfirm] = useState(true)
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (isUserSubmittable(user, isLogIn)) {
      console.log({ submittingUser: user })
      // updateUser({ isStayLoggedIn })
      onSubmit({
        ...user,
        isStayLoggedIn,
      })
      const contactInfo = {
        phone: user?.phone,
        email: user?.email,
        isStayLoggedIn,
      }
      window.sessionStorage.setItem('userContact', JSON.stringify(contactInfo))
      Router.push(`/confirm${ queryPath }`)
    }
  }

  useEffect(() => {
    if (isUsingPhoneNumberToConfirm) {
      updateUser({ email: '' })
    }
    updateUser({ phone: '' })
  }, [isUsingPhoneNumberToConfirm])

  const contactNoun = isUsingPhoneNumberToConfirm ? 'Phone' : 'Email'

  const otherUrlPath = isLogIn ? 'signup' : 'login'
  const [queryPath, setQueryPath] = useState('')
  useEffect(() => {
    setQueryPath(getQueryPath())
  }, [])
  console.log({ signUpOrInErrors: userError })

  return <form className={styles.form} onSubmit={handleSubmit}>
    <ScrollToMe />
    <h3>
      { isLogIn
        ? 'Log in'
        : config.isWaitingListOn ? 'Request Early Access' : 'Contact info'
      }
      {' '}
      <Link href={`/${ otherUrlPath }${ queryPath }`}>
        { isLogIn
          ? <a className={styles.loginLink}>Need an account?</a>
          : <a className={styles.loginLink}>Already have an account?</a>
        }
      </Link>
    </h3>
    { !isLogIn && <div className={styles.leadInputContainer}>CardHero needs this information to send you service-related&nbsp;notifications.</div> }
    { !isLogIn && <div className={styles.leadInputContainer}>
      {/* {user && user._meta.errors && user._meta.errors.name && <div className={styles.errors}>
        {map(user._meta.errors.name, value => <span className={styles.error}>{ upperFirst(value) }</span>)}
      </div>} */}
      <label className={styles.label} htmlFor='leadInputName'>What to call you</label>
      <input
        className={styles.leadInput}
        id='leadInputName'
        type='text'
        value={user?.name ? user.name : ''}
        onChange={handleUpdateName(updateUser)}
        placeholder='Your first name'
      />
    </div> }

    <div className={styles.leadInputContainer}>
      { isUsingPhoneNumberToConfirm
        ? <>
          {/* {user && user._meta.errors && user._meta.errors.phone && <div className={styles.errors}>
            {map(user._meta.errors.phone, value => <span className={styles.error}>{ upperFirst(value) }</span>)}
          </div>} */}
          <label className={styles.label} htmlFor='leadInputPhone'>Where to text you</label>
          <PhoneInput
            className={styles.leadInputPhone}
            id='leadInputPhone'
            country='US'
            value={user?.phone || ''}
            onChange={handleUpdatePhone(updateUser)}
            placeholder='Mobile number'
          />
          <Clickable className={styles.switchButton} onClick={() => { console.log('USE EMAIL'); setIsUsingPhoneNumberToConfirm(false) }}>Or, <span className={styles.switchUnderline}>use email</span></Clickable>
        </>
        : <>
          {/* {user && user._meta.errors && user._meta.errors.phone && <div className={styles.errors}>
            {map(user._meta.errors.email, value => <span className={styles.error}>{ upperFirst(value) }</span>)}
          </div>} */}
          <label className={styles.label} htmlFor='leadInputEmail'>Where to email you</label>
          <div className={styles.leadInputPhoneContainer}>
            <input
              type='email'
              className={styles.leadInputEmail}
              id='leadInputEmail'
              value={user?.email || ''}
              onChange={handleUpdateEmail(updateUser)}
              placeholder='Email address'
            />
          </div>
          <Clickable className={styles.switchButton} onClick={() => { console.log('USE PHONE'); setIsUsingPhoneNumberToConfirm(true) }}>Or, <span className={styles.switchUnderline}>use phone</span></Clickable>
        </>
      }
      <div className={styles.stayLoggedIn}>
        <Checkbox
          label='Stay logged in'
          checked={isStayLoggedIn}
          onChange={toggleisStayLoggedIn}
          name='isStayLoggedIn'
        />
      </div>
    </div>

    <div className={styles.submitContainer}>
      { user?.signupStep === 1
        ? <Button variant='small' type='submit' isDisabled={!isUserSubmittable(user, isLogIn)}>Sending Verification...</Button>
        : <Button variant='small' type='submit' isDisabled={!isUserSubmittable(user, isLogIn)}>{ isLogIn ? 'Log in' : `Verify Your ${ contactNoun }` } &raquo;</Button>
      }
    </div>
    {/* <p className={typeStyles.bodyText}>Sign up now to be the first to know when CardHero is live –– and get a discount too!</p> */}
    { !isLogIn && <>
      <p className={typeStyles.smallText}>We will never spam you, because spam is awful and you are awesome.</p>
      <p className={typeStyles.smallText}>By pressing the <em>Verify Your { contactNoun }</em> button, you agree to the CardHero <Link href='/terms'><a target='termsofuse'>Terms of Use</a></Link> and <Link href='/privacy'><a target='privacypolicy'>Privacy Policy</a></Link>, CardHero will use your { isUsingPhoneNumberToConfirm ? 'phone number' : 'email address' } to set up your account and send you notifications. { isUsingPhoneNumberToConfirm && <span>Carrier message/data rates may apply. </span>}</p>
    </> }
  </form>
}

export default SignUpOrIn
